'use client';

import { useTranslations } from 'next-intl';
import { Beardman404 } from '~/components/Beardman/Beardman404/Beardman404';
import { MaintenanceClouds } from '@/app/[locale]/maintenance/components/MaintenanceClouds';

export default function NotFound() {
  const t = useTranslations('error.404');

  return (
    <div className="maintenance-page__wrapper">
      <div className="maintenance-page__message">
        <h1 className="vp-title-m">404</h1>
        <p className="vp-body-l">
          {t('description')}
        </p>
        <Beardman404 />
        <MaintenanceClouds />
      </div>
    </div>
  );
}
